<template>
  <div>
    <table
      v-if="templates.length > 0"
      class="table table-hover table-striped borderless scrollable"
    >
      <thead>
        <!-- <th class="text-muted"><small>Tipo</small></th> -->
        <th class="text-muted"><small>Tipo de Notificação</small></th>
        <th class="text-muted"><small>Canal</small></th>
        <th class="text-muted"><small>Aplicação</small></th>
        <th class="text-muted" style="width: 5%"><small>Ativo</small></th>
        <th class="text-muted"><span class="badge">Legado</span></th>
        <th class="text-muted"><span class="badge">Padrão</span></th>
        <th class="text-muted" style="width: 5%"><small>Ações</small></th>
      </thead>

      <tbody>
        <tr v-for="(template, idx) in templates" :key="idx">
          <!-- <td>{{ templateType(template) }}</td> -->
          <td>
            {{
              notificationTypesById[template.notification_type_id].description
            }}
          </td>
          <td>{{ channelsById[template.channel_id].channel }}</td>
          <td v-if="template.application_id">{{ applicationById[template.application_id].name }}</td>
          <td v-else>Para todas as aplicações</td>
          <td>
            <div :id="template.id">
              <b-form-checkbox
                v-model="template.active"
                value="1"
                unchecked-value="0"
                switch
                :disabled="template.active == '1'"
                @change="change_active({ template, value: $event })"
              />
            </div>
          </td>
          <b-popover
            variant="danger"
            :disabled="template.active == '0'"
            :target="template.id"
            triggers="hover"
            placement="top"
          >
            <template #title>Não permitido</template>
            <div class="p-2">
              <span
                >Para desativar um modelo, é necessário, que outro esteja ativo,
                clique no modelo que deseja ativar e automaticamente
                será trocado.</span
              >
            </div>
          </b-popover>
          <td v-if="template.legacy == '1'">
            <b-badge pill variant="success">Sim</b-badge>
          </td>
          <td v-else><b-badge pill variant="gray">Não</b-badge></td>
          <td v-if="template.default == '1'">
            <b-badge pill variant="success">Sim</b-badge>
          </td>
          <td v-else><b-badge pill variant="gray">Não</b-badge></td>
          <td class="d-flex">
            <b-button
              class="mr-1"
              @click="view_template(template.html)"
              variant="gray"
              >Ver</b-button
            >
            <b-button
              v-if="
                template.legacy == '0' &&
                permissions.includes('editar') &&
                template.default == '0'
              "
              @click="$router.push(`/sistemicos/modelo/${template.id}/editar`)"
              variant="gray"
              >Editar</b-button
            >
            <div v-else :id="`popover-legacy-${idx}`">
              <b-button variant="gray" disabled> Editar </b-button>
              <b-popover
                :target="`popover-legacy-${idx}`"
                triggers="hover"
                placement="lefttop"
                variant="info"
              >
                <div class="p-2">
                  Templates legado e padrão não podem ser editados
                </div>
              </b-popover>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <b-row v-else>
      <b-col md="12">
        <div class="py-3 panel-warning">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <fa-icon
              icon="exclamation-circle"
              class="text-warning font-size"
            ></fa-icon>
            <p>Nenhum template criado</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <SweetModal ref="modal" width="80%">
      <iframe
        :srcdoc="htmlTemplate"
        frameborder="0"
        style="width: 100%; height: 75vh"
      ></iframe>
    </SweetModal>
    <ExistingModalPreview
      ref="existingPreview"
      :currentModel="activeModel.currentModel"
      :newModel="activeModel.newModel"
      @cancel="cancel_new_model"
      @change_active_model="change_active_model"
    />    
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      activeModel: {
        currentModel: "",
        newModel: "",
      },
      htmlTemplate: "",
    };
  },  
  components: {
    FormSelect2: () => import("@/components/inputs/FormSelect2WayBinding"),
    ExistingModalPreview: () => import("./ExistingModalPreview.vue"),
  },
  computed: {
    templates(){
      return this.$store.state.sistemic.modelsList
    },
    applications() {
      return this.$store.state.global.applications;
    },
    channels() {
      return this.$store.state.global.channels;
    },
    notificationTypes() {
      return this.$store.state.global.notificationTypes;
    },

    applicationById() {
      return _.keyBy(this.applications, "id");
    },
    channelsById() {
      return _.keyBy(this.channels, "id");
    },
    notificationTypesById() {
      return _.keyBy(this.notificationTypes, "id");
    },
    permissions() {
      let permissions = [];
      try {
        const response =
          this.$store.state.auth.burgerPermissions.sistemicos.modelos.habilitar;
        if (response) {
          permissions = response;
        }
      } catch (e) {}

      return permissions;
    },
  },
  methods: {
    view_template(html) {
      this.htmlTemplate = html;
      this.$refs.modal.open();
    },

    changeFilter(val) {
      this.selected = val;
    },
    templateType(data) {
      if (data.type == "header") {
        return "Cabeçalho";
      } else if (data.type == "content") {
        return "Corpo";
      } else {
        return "Rodapé";
      }
    },
    application_name(template) {
      if (template.application_id) {
        return this.application_name_by_id[template.application_id].name;
      } else {
        return `Todas as Aplicações`;
      }
    },
    change_active(value) {
      if (value.value == "1") {
        const newModel = value.template;
        const currentModel = _.filter(this.templates, (m) => {
          return (
            m.notification_type_id == newModel.notification_type_id &&
            m.active == "1" &&
            m.id !== newModel.id
          );
        });
        this.activeModel.currentModel = currentModel[0];
        this.activeModel.newModel = newModel;
        this.$refs.existingPreview.open();
      } else {
        value.template.active = "1";
        this.$bvToast.toast(
          "Não é possível desativar um template, caso queira ativar outro, clique no botão correspondente ao item desejado.",
          {
            title: "Erro ao Alterar",
            variant: "danger",
          }
        );
      }
    },
    cancel_new_model() {
      const index = _.findIndex(this.templates, (model) => {
        return model.id == this.activeModel.newModel.id;
      });
      this.templates[index].active = "0";
      this.activeModel.newModel = "";
      this.activeModel.currentModel = "";
    },
    change_active_model() {
      let newModel = this.activeModel.newModel;
      let currentModel = this.activeModel.currentModel;
      newModel.active = "1";
      currentModel.active = "0";
      Promise.all([
        this.$store.dispatch("global/update_model", newModel),
        this.$store.dispatch("global/update_model", currentModel),
      ]).then((result) => {
        let error = [];
        _.forEach(result, (item) => {
          if (!item) {
            error.push(item);
          }
        });
        if (error.length > 0) {
          this.$bvToast.toast(error, {
            title: "Erro ao Alterar",
            variant: "danger",
          });
        } else {
          this.$bvToast.toast("Tipo de notificação alterado com sucesso!", {
            title: "Tudo Certo!",
            variant: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.borderless td,
.borderless th,
.borderless thead {
  border: none;
  border-bottom: none;
}
td {
  vertical-align: middle !important;
}
.font-size {
  font-size: 3rem;
}
.panel-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
</style>